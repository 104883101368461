@import 'variables';

html {
  height: 100%;
  min-width: 1900px;
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;
}

body,
.p-dialog .p-dialog-content {
  color: $gray-12;
  min-height: 100%;
}

.p-dialog {
  z-index: 600 !important;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 1px;
  margin: $grid-gutter-half 0;
}

.no-padding {
  padding: 0;
}

.page-padding {
  padding: $grid-gutter;
}

.page-header {
  font-size: $font-size-h1;
  font-weight: normal;
  margin-bottom: $grid-gutter-half;
  margin-top: 0;

  &--small {
    font-size: $font-size-sm;
    margin-bottom: 0;
  }
}

.section-padding {
  padding: $grid-gutter-half;
}

.section-header {
  font-size: $font-size-h1;
  margin-bottom: $grid-gutter-half;
  text-transform: uppercase;

  &--small {
    font-size: $font-size-sm;
    margin-bottom: 0;
  }
}

.text-left {
  text-align: start !important;
}

.text-right {
  text-align: end !important;
}

.text-center {
  text-align: center !important;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.attention-tooltip {
  &.tooltip-message {
    max-width: 300px;

    .ui-tooltip-text {
      background: $attention-4;
      color: $text-color;
    }
  }
}
// Cursor classes - self declared please use at the according areas
.cursor-clickable {
  cursor: pointer;
}
.cursor-under-construction {
  cursor: no-drop;
}
.cursor-help {
  cursor: help;
}
