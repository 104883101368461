@import 'variables';

.col-form-label,
.col-form-value {
  padding: $grid-gutter-quarter;
  margin-bottom: 0;
  font-size: inherit;
}

.form-group {
  margin-bottom: $grid-gutter-quarter;

  &--inline {
    align-items: center;
    display: flex;
  }

  .form-control {
    .ui-inputtext {
      width: 100%;
    }
  }
}

.ui-spinner {
  .ui-spinner-input {
    padding-right: 6px !important;
  }

  .ui-spinner-button {
    display: none;
  }
}

.pallet-card {
  .ui-spinner {
    .ui-spinner-input {
      width: 80px;
    }
  }
}

.radio {
  left: -9999px;
  position: absolute;

  & + label {
    cursor: pointer;
    display: block;
    margin: 0;
    height: 22px;
    line-height: 22px;

    &::after {
      clear: both;
      content: '';
      display: table;
    }

    &::before {
      transition: border-color 0.2s ease;
      background-color: white;
      border: solid 1px $default-input-border;
      border-radius: 50%;
      bottom: 0;
      margin-right: 5px;
      content: '';
      cursor: pointer;
      display: inline-block;
      height: 20px;
      position: relative;
      width: 20px;
      vertical-align: middle;
    }

    &:focus,
    &:active {
      &::before {
        box-shadow: 0 0 0 0.2em $default-input-focus;
      }
    }
  }

  &:disabled + label {
    color: $blue-3;
  }

  &:checked + label::before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14px' height='14px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:sketch='http://www.bohemiancoding.com/sketch/ns'%3e%3cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' sketch:type='MSPage'%3e%3cg sketch:type='MSArtboardGroup' fill='%23ffffff'%3e%3ccircle id='Oval-1' sketch:type='MSShapeGroup' cx='8' cy='8' r='5'%3e%3c/circle%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    background-color: $primary-4;
    background-repeat: no-repeat;
    background-position: center center;
    border-color: $primary-4;
  }

  &:focus,
  &:hover {
    & + label::before {
      border-color: $primary-4;
    }
  }

  &--light + label {
    color: #fff;

    &::before {
      background-color: white;
      border-color: white;
    }
  }
}

.form-control {
  &--lg {
    padding: $grid-gutter-half;
    font-size: $font-size-md;
    line-height: $font-size-lg;
  }
}

body {
  .btn--lg {
    .ui-button.ui-button-text-only {
      .ui-button-text {
        font-size: $font-size-md;
        line-height: $font-size-lg;
        padding: $grid-gutter-half;
      }
    }
  }
}

.search-bar {
  background: white url('/assets/icons/src/search.svg') no-repeat end 10px center !important;
  width: 100%;
}
