@import 'variables';

.mount-pallet-list {
  border: $default-border;
  padding: 5px 25px;
  display: flex;

  &--dummy {
    background-color: $dummy-pallet-color;
  }

  &__item {
    height: 100%;
    min-height: 70px;
    width: 100%;

    &--empty {
      justify-content: center;
      align-items: center;
    }
  }

  &__kpi:not(:last-child) {
    margin-right: $doubled-default-margin;
  }

  &__pallet-info {
    display: flex;
    align-items: flex-end;
  }

  &__actions {
    display: grid;
    grid-template-columns: 180px 150px;
    grid-template-rows: 100%;
    margin-left: 12px;

    .waste-actions {
      font-weight: bold;
      font-size: $font-size-h1;
      text-align: end;
      align-self: center;

      &__unit {
        padding-left: 5px;
        font-size: $font-size-sm;
        color: $gray-8;
      }
    }
  }

  &__article {
    display: grid;
    grid-template-columns: 120px 120px;
    grid-template-rows: 100%;
    margin-left: 20px;

    .article-action {
      font-weight: bold;
      font-size: $font-size-h4;
      text-align: left;
      align-self: left;
    }
  }

  &__other-actions {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;

    .input-icon {
      font-size: $font-size-h1;
      margin-right: 7px;

      &--dismount {
        font-size: $font-size-display-lg;
        color: $error-4;
        cursor: pointer;
        margin-right: 0;
        margin-left: 14px;
      }
    }

    .label {
      font-size: $font-size-h2;
      margin-right: 7px;
    }
  }
}
