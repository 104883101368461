/* You can add global styles to this file, and also import other style files */

@import 'assets/bootstrap/bootstrap-grid.min.css';
@import 'assets/icons/style.css';
@import 'variables';
@import 'inter';
@import 'global';
@import 'form';
@import 'table';
@import 'tabs';
@import 'icons';
@import 'mixins';
@import 'buttons';
@import 'qa-check';
@import 'mounting-container-list';
@import 'status-flag';
@import 'dropdown';
@import 'inputs';
@import 'dialogs';
@import 'calendar';

$color-light-yellow: #fff3cd;
$color-light-amber: #ffeeba;

:root {
  font-family: $font-family;
  --font-family: $font-family;
  font-feature-settings: $font-features;
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: 'InterVariable', sans-serif;
    font-optical-sizing: auto;
    --font-family: 'InterVariable', sans-serif;
    font-optical-sizing: auto;
  }
}

.no-scroll-dialog {
  .p-dialog-content {
    overflow: hidden;
  }
}

.time-out-modal {
  .question {
    text-align: start !important;
  }
}

.p-overflow-hidden {
  overflow: visible;
}

.separator {
  background-color: #f5f5f5;
  height: 10px;
}

.readonly-mode {
  pointer-events: none;
}

.readonly-message {
  color: $error-4;
  font-weight: bold;
  text-align: center;
  background-color: $color-light-yellow;
  border: 1px solid $color-light-amber;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}
